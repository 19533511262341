<template lang="pug">
.page
  navbar

  section.first
    h1.animation.left.animated {{ $t('.firstSection.title') }}

    .animation.right.animated
      p {{ $t('.firstSection.content') }}

      .buttons-container
        router-link.button.button-primary(
          :to="{ name: 'contact' }"
        )
          | {{ $t('.buttons.contactUs') }}

        button.partners-button.button-primary-outline(
          @click="showPartnersModal = true"
        )
          | {{ $t('.buttons.findPartners') }}

  section.second
    h2.animation.top.animated {{ $t('.secondSection.title.firstPart') }} 
      span {{ $t('.secondSection.title.secondPart') }}

    .infos
      .info.animation.bottom(v-for="(value, key) in iconsSecondSection")
        img(:src="mediaPath(value)")

        .content
          h3 {{ $t(`.secondSection.infos.${key}.title`) }}

          p {{ $t(`.secondSection.infos.${key}.content`) }}

  Footer

  partners-modal(:show="showPartnersModal" @close="showPartnersModal = false")
</template>

<script>
import Navbar from './components/navbar.vue'
import Footer from './components/footer.vue'
import PartnersModal from './components/partners-modal.vue'

export default {
  components: { Navbar, Footer, PartnersModal },

  data() {
    return {
      // i18n
      defaultI18nScope: "staticPages.forTenants",

      showPartnersModal: false,

      iconsSecondSection: {
        first: 'house-person-leave.svg',
        second: 'file-slash.svg',
        third: 'eye.svg',
        fourth: 'money-check-dollar-pen.svg',
        fifth: 'people-group.svg'
      },
    }
  },

  mounted() {
    window.addEventListener("scroll", this.animateElements);
  },

  methods: {
    animateElements() {
      const elements = document.querySelectorAll(".animation");

      elements.forEach((element) => {
        const windowHeight = window.innerHeight
        const elementTop = element.getBoundingClientRect().top
        const elementVisible = 50

        if (elementTop < (windowHeight - elementVisible) && !element.classList.contains('animated')) {
          element.classList.add("animated");
        }
      })
    },

    mediaPath(file) {
      return require(`@/assets/images/for_tenants/${file}`)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../styles/static_pages/section_padding";

  $primary-high-light-color: #EEE5FB;
  $primary-light-color: #A575EC;
  $primary-color: #8E4EC5;
  $primary-dark-color: #452763;
  $yellow-color: #FFE040;

  .animation {
    opacity: 0;

    &.animated {
      opacity: 1;
      animation-timing-function: ease-out;
      animation-duration: 1.5s;

      &.left {
        animation-name: moveInLeft;
      }

      &.right {
        animation-name: moveInRight;
      }

      &.bottom {
        animation-name: moveInBottom;
      }

      &.top {
        animation-name: moveInTop;
      }
    }
  }

  section {
    width: 100%;
    padding: 12rem 6rem;
  }

  p {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.7rem;

    &.subtitle {
      margin-bottom: 1.6rem;
      font-size: 1.6rem;
      font-weight: 600;
    }
  }

  h2 {
    font-size: 4.8rem;
  }

  h1, h2, h3 {
    font-weight: 800;
  }

  h1, h2, h3, p {
    margin: 0;
    font-family: 'Open Sans';
    color: $primary-dark-color;

    span {
      color: $white-color;
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5rem;
    height: auto;

    button, .button {
      margin: 0;
      padding: 0 2.4rem;
      height: 4.8rem;
      line-height: 4.8rem;

      text-transform: none;
      font-size: 1.6rem;
      font-weight: 700;

      &.button-primary-outline {
        background-color: $primary-high-light-color;

        &:hover {
          background-color: $primary-color;
        }
      }
    }
  }

  section.first {
    background-image: url('~src/assets/images/for_tenants/city.svg');
    background-size: auto 95%;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: $primary-high-light-color;

    border-top: 6rem solid $primary-high-light-color;
    padding: 27rem 6rem 27rem 6rem;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8rem;
    align-items: center;

    h1 {
      font-size: 5.6rem;
      line-height: 6.7rem;

      margin-bottom: 2.4rem;
    }

    p {
      margin-bottom: 4rem;
    }
  }

  section.second {
    background-color: $primary-light-color;

    display: flex;
    flex-direction: column;
    gap: 8rem;

    align-items: center;
    text-align: center;

    h2 {
      width: 75%;

      span {
        color: $white-color;
      }
    }

    .infos {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 4.8rem;

      .info {
        width: 30%;
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        align-items: center;

        img {
          height: 4rem;
          width: 4rem;
        }

        h3 {
          font-size: 2.4rem;
          margin-bottom: 2.4rem;
        }

        p {
          font-size: 1.6rem;
        }
      }
    }
  }

  @media only screen and (max-width: 1080px) {
    section.second {
      h2 {
        width: 100%;
      }

      .infos .info {
        width: 40%;
      }
    }
  }

  @media only screen and (max-width: 930px) {
    section {
      padding: 6.4rem 2rem;
    }

    p {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
    }

    h2 {
      font-size: 3.6rem;
    }

    h1, h2, h3 {
      font-weight: 700;
    }

    section.first {
      display: block;
      padding: 16rem 2rem 10rem 2rem;

      h1 {
        margin-bottom: 2rem;

        font-size: 4rem;
        line-height: 4.8rem;
      }

      p {
        margin-bottom: 2.4rem;
      }
    }

    section.second {
      gap: 4rem;

      h2 {
        font-size: 3.2rem;
      }

      .infos .info {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    section.first {
      background-size: auto 70%;
      background-position: 15% bottom;
    }
  }
</style>
