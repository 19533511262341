<template lang="pug">
.property-section
  select-field(
    name="contract[property_attributes][property_type]"
    :options="propertyTypeOptions"
    :error="errors?.propertyPropertyType?.message"
    v-model="modelValue.propertyType"
    includeBlank
    required
  )

  input-field(
    type="text"
    name="contract[property_attributes][zip_code]"
    :placeholder="$t('.placeholder.zipCode')"
    :error="errors?.propertyZipCode?.message"
    @input="getAddress"
    v-model="modelValue.cep"
    v-maska="'#####-###'"
    required
  )

  input-field(
    type="text"
    name="contract[property_attributes][state]"
    :placeholder="$t('.placeholder.state')"
    :error="errors?.propertyState?.message"
    v-model="modelValue.state"
    required
  )

  input-field(
    type="text"
    name="contract[property_attributes][city]"
    :placeholder="$t('.placeholder.city')"
    :error="errors?.propertyCity?.message"
    v-model="modelValue.city"
    required
  )

  input-field(
    type="text"
    name="contract[property_attributes][neighborhood]"
    :placeholder="$t('.placeholder.neighborhood')"
    :error="errors?.propertyNeighborhood?.message"
    v-model="modelValue.neighborhood"
    required
  )

  input-field(
    type="text"
    name="contract[property_attributes][street]"
    :placeholder="$t('.placeholder.street')"
    :error="errors?.propertyStreet?.message"
    v-model="modelValue.street"
    required
  )

  input-field(
    type="number"
    name="contract[property_attributes][number]"
    :placeholder="$t('.placeholder.number')"
    :error="errors?.propertyNumber?.message"
    v-model="modelValue.number"
    required
  )

  input-field(
    type="text"
    name="contract[property_attributes][complement]"
    :placeholder="$t('.placeholder.complement')"
    :error="errors?.propertyComplement?.message"
    v-model="modelValue.complement"
  )
</template>

<script>
import cep from 'cep-promise'

export default {
  data() {
    return {
      // i18n
      defaultI18nScope: 'realEstate.management.contract.new'
    }
  },

  props: {
    modelValue: { type: Object, required: true },
    errors: { type: Object, default: () => ({}) }
  },

  computed: {
    propertyTypeOptions() {
      return [
        { id: 'apartment', text: this.$t('.propertyTypeOptions.apartment') },
        { id: 'house', text: this.$t('.propertyTypeOptions.house') },
        { id: 'condominium_house', text: this.$t('.propertyTypeOptions.condominiumHouse') },
        { id: 'roof_top', text: this.$t('.propertyTypeOptions.roofTop') },
        { id: 'commercial', text: this.$t('.propertyTypeOptions.commercial') },
        { id: 'flat', text: this.$t('.propertyTypeOptions.flat') }
      ]
    }
  },

  methods: {
    getAddress(value) {
      let cepValue = value.target.value.replace('-', '')

      if (cepValue.length < 8) return

      cep(cepValue).then((result) => {
        this.modelValue.state = result.state
        this.modelValue.neighborhood = result.neighborhood
        this.modelValue.city = result.city
        this.modelValue.street = result.street
        this.modelValue.cep = `${result.cep.slice(0, 5)}-${result.cep.slice(5)}`
      })
    }
  }
}
</script>
