<template lang="pug">
.tenant-section
  .inline-section
    select-field.three-columns(
      v-if="!!scoreReport"
      name="contract[tenant]"
      :label="$t('.form.fields.tenant')"
      :options="tenantOptions"
      @update:modelValue="selectTenant"
      v-model="modelValue.selectedTenant"
      includeBlank
      required
    )

    input-field.three-columns(
      type="text"
      name="contract[tenant_attributes][name]"
      :placeholder="$t('.placeholder.name')"
      :error="errors?.tenantName?.message"
      v-model="modelValue.name"
      readonly
      required
    )

    input-field.three-columns(
      type="text"
      name="contract[tenant_attributes][cpf]"
      v-maska="'###.###.###-##'"
      :placeholder="$t('.placeholder.cpf')"
      :error="errors?.tenantCpf?.message"
      v-model="modelValue.cpf"
      readonly
      required
    )

  .inline-section
    input-field.three-columns(
      type="text"
      name="contract[tenant_attributes][rg]"
      :placeholder="$t('.placeholder.rg')"
      :error="errors?.tenantRg?.message"
      v-model="modelValue.rg"
      required
    )

  h3 {{ $t('.form.emails') }}

  .inline-section
    input-field.three-columns(
      type="email"
      name="contract[tenant_attributes][emails_attributes][][address]"
      :label="$t('models.contract.attributes.firstEmail')"
      :placeholder="$t('.placeholder.email')"
      :error="errors?.tenantEmailsAddress?.message"
      v-model="modelValue.firstEmail"
      required
    )

    input-field.three-columns(
      type="email"
      name="contract[tenant_attributes][emails_attributes][][address]"
      :label="$t('models.contract.attributes.secondEmail')"
      :placeholder="$t('.placeholder.email')"
      :error="errors?.tenantEmailsAddress?.message"
      v-model="modelValue.secondEmail"
    )

    input-field.three-columns(
      type="email"
      name="contract[tenant_attributes][emails_attributes][][address]"
      :label="$t('models.contract.attributes.thirdEmail')"
      :placeholder="$t('.placeholder.email')"
      :error="errors?.tenantEmailsAddress?.message"
      v-model="modelValue.thirdEmail"
    )

  h3 {{ $t('.form.phones') }}

  .inline-section
    phone-field.three-columns(
      name="contract[tenant_attributes][phones_attributes][][number]"
      :label="$t('models.contract.attributes.firstPhone')"
      required
      :placeholder="$t('.placeholder.phone')"
      :error="errors?.tenantPhonesNumber?.message"
      v-model="modelValue.firstPhone"
    )

    phone-field.three-columns(
      name="contract[tenant_attributes][phones_attributes][][number]"
      :label="$t('models.contract.attributes.secondPhone')"
      :placeholder="$t('.placeholder.phone')"
      :error="errors?.tenantPhonesNumber?.message"
      v-model="modelValue.secondPhone"
    )

    phone-field.three-columns(
      name="contract[tenant_attributes][phones_attributes][][number]"
      :label="$t('models.contract.attributes.thirdPhone')"
      :placeholder="$t('.placeholder.phone')"
      :error="errors?.tenantPhonesNumber?.messages"
      v-model="modelValue.thirdPhone"
    )
</template>

<script>
export default {
  data() {
    return {
      // i18n
      defaultI18nScope: 'realEstate.management.contract.new'
    }
  },

  props: {
    modelValue: { type: Object, required: true },
    errors: { type: Object, default: () => ({}) },
    scoreReport: { type: Object, default: () => ({}) }
  },

  mounted() {
    const selectedTenant = this.modelValue.selectedTenant

    if (selectedTenant) this.selectTenant(selectedTenant)
  },

  methods: {
    selectTenant(value) {
      const score = this.scores?.find(({ id }) => id == value)

      if (score) {
        this.modelValue.name = score.name
        this.modelValue.cpf = score.cpf

        return
      }

      delete this.modelValue.selectedTenant
      delete this.modelValue.name
      delete this.modelValue.cpf
    }
  },

  computed: {
    scores() {
      return this.scoreReport?.scores
    },

    tenantOptions() {
      if (!this.scores) return []

      return this.scores.map(({ id, cpf, name }) => ({
        id, text: `${cpf} - ${name}`
      }))
    }
  }
}
</script>