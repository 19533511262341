<template lang="pug">
.page.w-full
  navbar

  section.first.px-20.pb-20.pt-40
    .city-background.w-full.grid.grid-cols-2
      .left-side.my-28.ml-24
        h1.font-extrabold
          | {{ $t('.firstSection.title.firstPart') }} 
          span {{ $t('.firstSection.title.secondPart') }}

        p.font-normal.text-justify
          | {{ $t('.firstSection.content') }}

        .buttons-container.flex.flex-row.flex-wrap.gap-8.mb-8
          button.button-primary.m-0.normal-case(@click="showSignUpModal = true")
            | {{ $t('.buttons.signUp') }}

          router-link.button.button-primary-outline.m-0.normal-case(
            :to="{ name: 'contact' }"
          )
            | {{ $t('.buttons.knowMore') }}

      .right-side.flex.items-end
        img.w-full(src="@/assets/images/new_home/couple.webp")

  section.second.py-20
    p.text-center.mb-12 {{ $t('.secondSection.content') }}

    carousel(
      :items-to-show="2.5"
      :autoplay="true"
      :wrapAround="true"
      :pauseAutoplayOnHover="true"
      transition="2500"
      snapAlign="center"
      :breakpoints="{ 500: { itemsToShow: 5.5 } }"
    )
      slide(v-for="partner in partners" :key="partner.url")
        a.partiner(:href="partner.url" target="_blank")
          img.w-40(:src="getImgUrl(partner.name)")

  section.third.px-20.py-32.w-full.grid.grid-cols-2.gap-20
    .left-side.my-28.mr-24
      p.capitalize.primary-color {{ $t('.thirdSection.subTitle') }}

      h2.font-extrabold
        | {{ $t('.thirdSection.title.firstPart') }} 
        span {{ $t('.thirdSection.title.secondPart') }}

      p.font-normal.text-justify
        | {{ $t('.thirdSection.content') }}

      .buttons-container.flex.flex-row
        button.button-primary.my-0.normal-case(@click="showSignUpModal = true")
          | {{ $t('.buttons.signUp') }}

        router-link.button.button-primary-outline.mx-8.my-0.normal-case(
          :to="{ name: 'forRealEstateAgencies' }"
        )
          | {{ $t('.buttons.knowMore') }}

    .right-side

  section.fourth.px-20.py-32.w-full
    .content-container.grid.grid-cols-2.gap-20
      .left-side

      .right-side.my-28.mr-24
        p.label.capitalize.primary-color {{ $t('.fourthSection.subTitle') }}

        h2.font-extrabold
          | {{ $t('.fourthSection.title.firstPart') }} 
          span {{ $t('.fourthSection.title.secondPart') }}
          | {{ $t('.fourthSection.title.thirdPart') }}

        p.normal-case.text-justify
          | {{ $t('.fourthSection.content') }}

        .buttons-container.flex.flex-row
          button.button-white.my-0.normal-case(@click="showSignUpModal = true")
            | {{ $t('.buttons.signUp') }}

          router-link.button.button-white-outline.mx-8.my-0.normal-case(
            :to="{ name: 'forLandlords' }"
          )
            | {{ $t('.buttons.knowMore') }}

  section.fifth.px-20.pt-20.pb-32.w-full
    .content-container.flex.flex-row
      .left-side.px-20.py-60(class="w-7/12")
        p.label.capitalize.primary-color {{ $t('.fifthSection.subTitle') }}

        h2.font-extrabold
          | {{ $t('.fifthSection.title.firstPart') }} 
          span {{ $t('.fifthSection.title.secondPart') }}
        p.normal-case.text-justify
          | {{ $t('.fifthSection.content') }}

        .buttons-container.flex.flex-row
          button.button-primary.m-0.normal-case(
            @click="showPartnersModal = true"
          )
            | {{ $t('.buttons.findPartners') }}

          router-link.button.button-primary-outline.mx-8.my-0.normal-case(
            :to="{ name: 'forTenants' }"
          )
            | {{ $t('.buttons.knowMore') }}

      .right-side(class="w-5/12")

  section.sixth.px-20.pt-32.pb-32.w-full
    carousel(:wrapAround="true")
      slide(v-for="testimonial in testimonials" :key="testimonial.name")
        .carousel__item
          .stars-container
            i.mx-1.fa.fa-star(v-for="star in 5" :key="star")

          h3.font-extrabold.my-6
            | {{ testimonial.text }}

          .comment-owner.mt-5
            img.person.w-28.h-28(:src="testimonial.profile_image")

            .info.ml-5.text-left
              p.name.mb-1.font-bold {{ testimonial.name }}
              p.role.mt-0.font-thin {{ testimonial.role }}

            .real-estate.border-l-2.ml-10.pl-10
              img.h-28(:src="testimonial.real_estate_image")

          Pagination

        .addons
          navigation

  //- section.seventh.px-20.pt-40.pb-32.w-full.flex
    .left-side(class="w-5/12")
      h2.font-extrabold
        | {{ $t('.seventhSection.title') }}
      p.font-normal.text-justify
        | {{ $t('.seventhSection.content') }}
      .flex.flex-row
        button.button-primary-outline.m-0.normal-case
          | {{ $t('.seventhSection.button') }}
    .right-side.pl-28(class="w-7/12")
      .border-t
        dropdown.border-b(
          title="Qual o tempo mínimo de contrato?"
          text="bar"
        )
        dropdown.border-b(
          title="Qual o custo real do Alpop?"
          text="bar"
        )
        dropdown.border-b(
          title="Como que eu me inscrevo?" text="bar"
        )
        dropdown.border-b(
          title="Quais são os requisitos mínimos para ser aprovado?" text="bar"
        )
        dropdown.border-b(
          title="Como funcionam os custos de manutenção da propriedade?" text="bar"
        )

  Footer

  sign-up-modal(:show="showSignUpModal" @close="showSignUpModal = false")
  partners-modal(:show="showPartnersModal" @close="showPartnersModal = false")
</template>
<script>

import 'vue3-carousel/dist/carousel.css'
import Navbar from './components/navbar.vue'
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel'
import Dropdown from './components/dropdown.vue'
import Footer from './components/footer.vue'
import SignUpModal from './components/sign-up-modal.vue'
import PartnersModal from './components/partners-modal.vue'

export default {
  components: {
    Carousel,
    Slide,
    Navigation,
    Dropdown,
    Navbar,
    Footer,
    Pagination,
    SignUpModal,
    PartnersModal
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "staticPages.home",

      showSignUpModal: false,
      showPartnersModal: false,

      partners: [
        {
          url: 'https://www.aciradm.com.br/',
          name: 'Acir'
        },
        {
          url: 'https://www.altairpereiraimoveis.com.br/',
          name: 'Altair_Pereira_Imoveis'
        },
        {
          url: 'https://alvaromaia.com.br/',
          name: 'Alvaro_Maia'
        },
        {
          url: 'https://www.cardinali.com.br/',
          name: 'Cardinali'
        },
        {
          url: 'https://www.imobiliariacarval.com.br/',
          name: 'Carval'
        },
        {
          url: 'https://celemarimoveis.com.br/',
          name: 'Celemar'
        },
        {
          url: 'https://www.cmendesimoveis.com.br',
          name: 'CMendes'
        },
        {
          url: 'https://www.comerlato.com/',
          name: 'Comerlato'
        },
        {
          url: 'https://www.conectaimobiliaria.com.br/',
          name: 'Conecta'
        },
        {
          url: 'https://imobiliariae2.com.br/',
          name: 'e2'
        },
        {
          url: 'https://filiperochaimoveis.com/',
          name: 'Filipe_Rocha'
        },
        {
          url: 'https://www.fuhrosouto.com.br/',
          name: 'Fuhro_Souto'
        },
        {
          url: 'https://www.imovelcertobh.com.br/',
          name: 'Imovel_Certo'
        },
        {
          url: 'https://www.jlosso.com.br/',
          name: 'JLosso'
        },
        {
          url: 'https://legalizaimovel.negocio.site',
          name: 'Legaliza'
        },
        {
          url: 'https://www.luagge.com.br/',
          name: 'Luagge'
        },
        {
          url: 'https://www.marodimimoveis.com.br/',
          name: 'marodim'
        },
        {
          url: 'https://www.milioniimoveis.com.br/',
          name: 'Miloni'
        },
        {
          url: 'https://mpxbr.com',
          name: 'MPX'
        },
        {
          url: 'https://olivermarquesimoveis.com.br/',
          name: 'Oliver_Marques'
        },
        {
          url: 'https://www.openimobiliaria.com.br/',
          name: 'Open'
        },
        {
          url: 'https://pezinhoimoveis.com.br',
          name: 'Pezinho'
        },
        {
          url: 'https://www.raraimoveis.com.br/',
          name: 'Rara'
        },
        {
          url: 'https://www.remaxmediar.com.br/',
          name: 'Remax'
        },
        {
          url: 'https://ritt.com.br',
          name: 'Ritt'
        },
        {
          url: 'https://robuste.com.br',
          name: 'robuste'
        },
        {
          url: 'https://roca.com.br/',
          name: 'Roca'
        },
        {
          url: 'https://roqueimoveis.com.br/',
          name: 'Roque_Imoveis'
        },
        {
          url: 'https://rossiimoveisrc.com.br/',
          name: 'Rossi'
        },
        {
          url: 'https://imobsaopaulo.com.br/',
          name: 'sao_paulo'
        },
        {
          url: 'https://www.7cantos.com/',
          name: '7Cantos'
        },
        {
          url: 'https://www.tatimoveis.com.br/',
          name: 'Tati'
        },
        {
          url: 'https://www.teixeiradecarvalho.com.br/',
          name: 'Teixeira_de_Carvalho'
        },
        {
          url: 'https://www.tofanoimoveis.com.br/',
          name: 'Tofano'
        },
        {
          url: 'https://topimoveissaocarlos.com.br/',
          name: 'Top'
        }
      ],

      testimonials: [
        {
          name: 'Pedro Jr.',
          role: 'Gerente de locação',
          text: 'A tecnologia da Alpop permitiu à Cardinali avançar ainda mais na construção de umas das maiores carteiras de locação do Brasil. A forma de operar e a tecnologia da Alpop trazem vantagem competitiva para a Cardinali e contribui para nosso objetivo de crescimento.',
          profile_image: require('@/assets/images/new_home/first_testimonial.jpeg'),
          real_estate_image: require('@/assets/images/new_home/cardinali.png'),
        },
        {
          name: 'Wiron Saldanha',
          role: 'COO',
          text: 'A forma de operação da Alpop permitiu melhorar os nossos indicadores financeiros, bem como aumentar o tempo de permanência no imóvel por parte dos inquilinos. Esses são dois indicadores chave para quem trabalha com locação e a tecnologia da Alpop é nossa principal aliada para buscarmos nossos objetivos.',
          profile_image: require('@/assets/images/new_home/second_testimonial.jpg'),
          real_estate_image: require('@/assets/images/new_home/7Cantos.png'),
        }
      ]
    }
  },

  mounted() {
    window.addEventListener("scroll", this.animateSections);
  },

  methods: {
    getImgUrl(pic) {
      return require(`@/assets/images/partners/${pic}@2x.png`)
    },

    animateSections() {
      const sections = document.querySelectorAll("section");

      sections.forEach((element) => {
        const windowHeight = window.innerHeight
        const elementTop = element.getBoundingClientRect().top
        const elementVisible = 400

        if (elementTop < (windowHeight - elementVisible) && !element.classList.contains('animated')) {
          element.classList.add("animated");
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .carousel__prev, .carousel__next {
    box-sizing: content-box;
    border: 2px solid white;
    color: $white-color;
    border-radius: 2rem;
    opacity: 0.5;

    &:hover {
      box-sizing: content-box;
      border: 2px solid white;
      color: $white-color;
      border-radius: 2rem;
      opacity: 1;
    }
  }

  .carousel__pagination {
    list-style-type: none !important;
     margin: 1.5rem 0 !important;

    .carousel__pagination-item {
      margin-top: 5rem;

      .carousel__pagination-button {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 2rem;
        margin: 0 0.2rem;

        background-color: $white-color;
        filter: opacity(50%);

        &.carousel__pagination-button--active {
          filter: opacity(100%);
        }

        &::after {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: 930px) {
    .addons {
      .carousel__prev, .carousel__next {
        display: none;
      }
    }
  }
</style>

<style lang="scss" scoped>
  p {
    font-family: 'Open Sans';
    font-size: 18px;
    line-height: 27px;
    color: #452763;
  }

  h1, h2, p {
    font-family: 'Open Sans';
    color: #452763;

    span {
      color: $primary-color
    }
  }

  section.first {
    background-color: #EEE5FB;

    .city-background {
      background-image: url('~src/assets/images/new_home/city.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
    }


    h1 {
      font-size: 5rem;
    }

    .left-side {
      animation-name: moveInLeft;
      animation-timing-function: ease-out;
      animation-duration: 1.5s;

      p {
        width: 85%;
      }
    }

    .button-primary-outline {
      background-color: #EEE5FB;

      &:hover {
        background-color: $primary-color;
      }
    }
  }

  section.second, section.fifth {
    background-color: #EEE5FB;
  }

  section.third {
    h2 {
      font-size: 4rem;
    }

    .left-side {
      opacity: 0;
    }

    &.animated {
      .left-side {
        opacity: 1;
        animation-name: moveInLeft;
        animation-timing-function: ease-out;
        animation-duration: 1.5s;
      }
    }

    .right-side {
      background-image: url("~src/assets/images/new_home/third-section.webp");
      background-size: cover;
    }
  }

  section.fourth {
    background-color: #EEE5FB;

    h2 {
      font-size: 4rem;
      color: $white-color;

      span {
        color: #FFE040;
      }
    }

    p {
      color: $white-color;

      &.label {
        color: #FFE040;
      }
    }

    .content-container {
      background: linear-gradient(90deg, #A575EC -0.04%, #8E4EC5 100.04%);
    }

    .left-side {
      background-image: url("~src/assets/images/new_home/fourth-section.webp");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom left;
    }

    .right-side {
      opacity: 0;
    }

    &.animated {
      .right-side {
        opacity: 1;
        animation-name: moveInRight;
        animation-timing-function: ease-out;
        animation-duration: 1.5s;
      }
    }
  }

  section.fifth {
    .content-container {
      background-color: $white-color;

      p {
        width: 80%;
      }

      .right-side {
        background-image: url("~src/assets/images/new_home/fifth-section.webp");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
      }
    }

    .left-side {
      opacity: 0;
    }

    &.animated {
      .left-side {
        opacity: 1;
        animation-name: moveInLeft;
        animation-timing-function: ease-out;
        animation-duration: 1s;
      }
    }
  }

  section.sixth {
    background: linear-gradient(180deg, #6B3B88 0%, #452763 100%);

    .carousel__item {
      width: 60%;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .carousel__slide {
      padding: 10px;
    }

    .stars-container {
      color: #FFE040;
    }

    h3 {
      color: $white-color;
      font-size: 2.4rem;
    }

    .comment-owner {
      display: flex;
      justify-content: center;
      align-items: center;

      img.person {
        border-radius: 7rem;
        object-fit: cover;
      }

      img {
        object-fit: contain;
      }

      display: flex;

      .info {
        p {
          color: $white-color;
        }
      }

      .real-estate {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media only screen and (max-width: 930px) {
    section.first {
      .city-background {
        background-size: percentage($number: 2.5);
        background-repeat: no-repeat;
        background-position: bottom;

        grid-template-columns: 1fr !important;

        .left-side {
          margin: 0 !important;
        }
      }
    }

    section.third {
      grid-template-columns: 1fr !important;

      .left-side {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
      }

      .right-side {
        height: 40rem;
      }
    }

    section.fourth {
      .content-container {
        display: flex !important;
        flex-direction: column-reverse;

        .right-side {
          margin: 0 !important;
          padding: 5rem !important;
          padding-bottom: 0 !important;
        }

        .left-side {
          height: 40rem;
        }
      }
    }

    section.fifth {
      .content-container {
        flex-direction: column !important;

        .left-side {
          padding: 5rem !important;
          width: 100% !important;

          p {
            width: 100%;
          }
        }

        .right-side {
          width: 100% !important;
          height: 40rem;
        }
      }
    }

    section.sixth {
      padding: 5rem !important;

      h3 {
        font-size: 2rem;
      }

      .carousel__item {
        width: 100%;

        .addons {
          display: none !important;
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .buttons-container {
      flex-direction: column !important;
      gap: 2rem !important;

      button, a {
        margin: 0 !important;
      }
    }

    section.first {
      padding-left: 2.5rem !important;
      padding-right: 2.5rem !important;

      h1 {
        font-size: 4rem;
      }

      .left-side {
       p {
          width: 100%;
        }
      }
    }

    section.second {
      padding-top: 0 !important;

      p {
        padding: 0 4rem;
      }
    }

    section.third {
      padding-left: 2.5rem !important;
      padding-right: 2.5rem !important;

      h2 {
        font-size: 2.5rem;
      }

      .left-side {
        margin-right: 0 !important;
      }
    }

    section.fourth {
      padding-left: 2.5rem !important;
      padding-right: 2.5rem !important;

      .content-container {
        .right-side {
          padding-left: 2.5rem !important;
          padding-right: 2.5rem !important;
          margin-right: 0 !important;

          h2 {
            font-size: 3rem;
          }
        }
      }
    }

    section.fifth {
      padding-left: 2.5rem !important;
      padding-right: 2.5rem !important;

      .content-container {
        .left-side {
          padding-left: 2.5rem !important;
          padding-right: 2.5rem !important;

          h2 {
            font-size: 3rem;
          }
        }
      }
    }

    section.sixth {
      padding-left: 2.5rem !important;
      padding-right: 2.5rem !important;
      .comment-owner {
        flex-direction: column;

        .info {
          text-align: center !important;
          margin-top: 1.5rem;
        }

        .real-estate {
          border: none !important;
          padding: 0 !important;
        }
      }
    }
  }

  section {
    &.first, &.third, &.fourth, &.fifth, &.sixth {
      @media only screen and (min-width: 1700px) {
        padding-inline: 10rem !important;
      }

      @media only screen and (min-width: 1800px) {
        padding-inline: 20rem !important;
      }

      @media only screen and (min-width: 2000px) {
        padding-inline: 30rem !important;
      }

      @media only screen and (min-width: 2150px) {
        padding-inline: 40rem !important;
      }

      @media only screen and (min-width: 2350px) {
        padding-inline: 50rem !important;
      }
    }
  }
</style>
