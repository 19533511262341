<template lang="pug">
.card-container
  .loading-container(v-if="status == 'wip' || status == 'todo'")
    .loader
    | {{ $t('.loading') }}
    .loading-info
      | {{ $t('.loadingInfo') }}

  .data-container
    div
      h3 {{ $t('.fields.cpfConsulted') }}

      .cpfs-container(:class="expanded ? 'expanded' : 'unexpanded'")
        .cpf-content(v-for="score in scores")
          .name-container
            h4 {{ $t('.fields.fullName') }}:
            p.name {{ score.name || '***** ** *****' }}

          .cpf-container
            h4 {{ $t('.fields.cpf') }}:
            p.cpf {{ score.cpf }}

        .cpf-content(v-if="fraudRisk")
          .name-container
            h4 {{ $t('.fields.fullName') }}:
            p.name {{ mainTenantName || '***** ** *****' }}

          .cpf-container
            h4 {{ $t('.fields.cpf') }}:
            p.cpf {{ mainTenantCpf }}

      .showing-container
        p {{ getShowingTotal }}

        a(v-if="scores.length > 1", @click="expanded = !expanded")
          | {{ getShowingText }}

    div
      h3 {{ $t('.fields.infoTitle') }}

      .info-container
        .created-by-container(v-if="createdBy")
          h4 {{ $t('.fields.createdBy') }}:
          p.creator-name {{ createdBy.name }}

        .property-type-container
          h4 {{ $t('.fields.propertyType') }}:
          p {{ $t(`.propertyTypes.${propertyType}`) }}

        .created-at-container
          h4 {{ $t('.fields.createdAt') }}:
          p {{ $l('date.formats.default', createdAt) }}

        .valid-until-container
          h4 {{ $t('.fields.validUntil') }}:
          p {{ getValidUntilText }}

        .contract-value-container(v-if="approved")
          h4 {{ $t('.fields.contractValue') }}:
          p.contract-value
            | R$ {{ contractOpeningValue && contractOpeningValue.toFixed(2) }}

        .reproved-by-container(v-else)
          h4 {{ $t('.fields.observation') }}:
          p.observation-value {{ getObservationText }}

      .flag.error-flag(v-if="status == 'error'", :class="status")
        | {{ $t(`.states.${status}`) }}
      .flag.done-flag(v-else, :class="getPredictionClass")
        | {{ getPredictionText }}

    .buttons-container
      button.button-primary.manual-analysis(
        v-if="showManualAnalysisButton && !approved && status == 'done'"
        :disabled="manualAnalysisSolicitation"
        @click="showManualAnalysisModal = true"
      )
        | {{ $t('.buttons.manualAnalysis') }}

      button.retry(
        v-if="status == 'error'",
        :disabled="submitting",
        @click="retryScoreReport"
      )
        | {{ $t('.buttons.retry') }}

      button.sent-contract(v-if="approved && sentToContract")
        | {{ $t(".buttons.sendContract.sent") }}

      button.send-contract(
        :disabled="$user.isLocked",
        v-else-if="approved",
        @click="showConfirmationModal"
      )
        | {{ $t(".buttons.sendContract.send") }}

      button.button-blue(
        @click="$router.push({ path: `${showPath || 'consultas'}/${id}` })"
      )
        | {{ $t('.buttons.show') }}

    .saas-message {{ $t('.saasMessage') }}

  manual-analysis-modal(
    v-if="showManualAnalysisModal && !performedManualAnalysis",
    @close="showManualAnalysisModal = false"
    :show="showManualAnalysisModal"
    :scoreReportPath="scoreReportPath"
    :callback="fetchCallback"
  )

  confirmation-modal(
    @close="showModal = false",
    :show="showModal",
    :title="$t('.modal.title')",
    :message="$t('.modal.message')",
    :callback="sendContract"
  )
</template>

<script>
import strings from "lib/strings"

import ManualAnalysisModal from "./manual-analysis-modal.vue"
import ConfirmationModal from "../../../components/confirmation-modal.vue"

export default {
  components: { ManualAnalysisModal, ConfirmationModal },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.components.scoreReport.card",

      submitting: false,
      expanded: false,
      showManualAnalysisModal: false,
      showModal: false
    }
  },

  props: {
    id: { type: Number, required: true },
    scores: { type: Array, default: () => { return [{}] } },
    prediction: { default: 'unexecuted' },
    approved: { type: Boolean, default: false },
    disapprovalSituation: { type: String },
    justificationForDisapproval: { type: String },
    fraudRisk: { type: Boolean, default: false },
    mainTenantCpf: { type: String, required: true },
    mainTenantName: { type: String, required: true },
    sentToContract: { type: Boolean, default: false },
    status: { type: String, default: 'wip' },
    fetchCallback: { type: Function, required: true },
    validUntil: { type: Date, required: true },
    createdBy: { type: Object, required: true },
    propertyType: { type: String, required: true },
    createdAt: { type: Date, required: true },
    url: { type: String, required: true },
    contractOpeningValue: { type: Number },
    manualAnalysisSolicitation: { type: Boolean, default: false },
    performedManualAnalysis: { type: Boolean, default: false },
    showPath: { type: String }
  },

  computed: {
    isManualAnalysisPending() {
      return this.manualAnalysisSolicitation && !this.performedManualAnalysis
    },

    getPredictionClass() {
      if (['wip', 'not_found'].includes(this.status)) { return 'unexecuted' }

      if (this.isManualAnalysisPending) return 'unexecuted'

      if (this.approved) { return 'approved' }

      return 'unapproved'
    },

    getShowingText() {
      return this.$t(this.expanded ? '.buttons.showOne' : '.buttons.showAll')
    },

    getShowingTotal() {
      return this.$t(
        '.showing.count',
        {
          count: this.expanded ? this.scores.length : 1,
          total: this.fraudRisk ? 1 : this.scores.length
        }
      )
    },

    getPredictionText() {
      const status = {
        wip: 'unexecuted', todo: 'unexecuted', not_found: 'notFound'
      }[this.status]

      if(status) { return this.$t(`.predictions.${status}`) }

      if (this.isManualAnalysisPending) {
        return this.$t('.predictions.manualAnalysisSolicitation')
      }

      const predictionPath = (result) => {
        const performedManualAnalysis = this.performedManualAnalysis

        return performedManualAnalysis ? `${result}ByManualAnalysis` : result
      }

      if (this.approved) {
        return this.$t(
          `.predictions.${predictionPath('approved')}`,
          { prediction: this.prediction }
        )
      }

      return this.$t(`.predictions.${predictionPath('unapproved')}`)
    },

    getValidUntilText() {
      if(this.status == 'done') {
        return this.$l('date.formats.default', this.validUntil)
      } else {
        return '-'
      }
    },

    getObservationText() {
      if (this.fraudRisk && this.status != 'not_found') {
        return this.$t('.observations.fraudRisk')
      }

      if (this.performedManualAnalysis) {
        return this.justificationForDisapproval || '-'
      }

      if (!this.disapprovalSituation) { return '-' }

      const formattedSituation = strings.camelCase(this.disapprovalSituation)

      return this.$t(`.observations.${formattedSituation}`)
    },

    scoreReportPath() {
      return `${this.$user.prefixUrlAgency}/score-reports/${this.id}`
    },

    showManualAnalysisButton() {
      const config = this.$firebase.remoteConfigValue('enableManualAnalysis')
      const agency = this.$agency.get

      return config.includes(agency.id)
    }
  },

  methods: {
    retryScoreReport() {
       this.submitting = true
      const formData = new FormData()

      return this.$http
        .post(`${this.url}/score-reports/${this.id}/retries`, formData)
        .then(() => {
          this.$notifications.clear()

          this.$notifications.info(this.$t(".notifications.retry.success"))

          this.$emit("refresh")
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.retry.failure"))
        })
        .then(() => {
          this.submitting = false

          this.fetchCallback()
        })
    },

    showConfirmationModal(){
      this.showModal = true
    },

    sendContract(){
      const formData = {
        score_report: {
          sent_to_contract: true
        }
      }

      this.$http
        .put(`${this.url}/score-reports/${this.id}`, formData)
        .then(() => {
          this.fetchCallback()

          this.errors = {}
          this.showModal = false

          this.$notifications.clear()
        })
        .catch(() => {
          this.$notifications.error(
            this.$t(".notifications.sendContract.failure")
          )
        })
    },
  }
}
</script>

<style scoped lang="scss">
.card-container {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  position: relative;
  max-width: 100%;
  padding: 2rem;
  height: fit-content;
  overflow: hidden;

  .data-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(235, 235, 235, 0.85);
    margin: -2rem 0 0 -2rem;
    border-radius: 0.5rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 1.7rem;
    font-weight: 400;
    color: $primary-color;
  }

  .loading-info {
    font-size: 1.1rem;
  }

  h3 {
    font-size: 1.7rem;
    font-weight: 500;
    color: $second-color-light;
    margin: 0;
    margin-bottom: 1rem;
    text-align: center;
  }

  .info-container, .cpfs-container {
    border-bottom: 1.5px solid $primary-color;
    margin-bottom: 1.5rem;
  }

  .cpf-content {
    margin-bottom: 1rem;
  }

  .cpfs-container.unexpanded .cpf-content:not(:first-child) {
    display: none;
    transition: opacity 1s ease-out;
    height: 0;
    opacity: 0;
  }

  .cpfs-container.expanded .cpf-content:not(:first-child) {
    display: block;
    transition: opacity 1s ease-out;
    opacity: 1;
    height: auto;
  }

  .showing-container {
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
    margin-top: -1rem;

    p {
      font-size: 1.3rem;
      color: $grey-color;
    }

    a {
      font-size: 1.3rem;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .info-container, .cpf-content {
    display: flex;
    flex-direction: column;

    .name-container,
    .cpf-container,
    .property-type-container,
    .created-at-container,
    .valid-until-container,
    .contract-value-container,
    .reproved-by-container,
    .created-by-container {
      display: flex;

      h4 {
        font-size: 1.5rem;
        font-weight: 700;
        margin: 0;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        min-width: max-content;
      }

      p {
        font-size: 1.5rem;
        color: $second-color;
        margin: 0;
        text-overflow: ellipsis;
      }
    }

    .name {
      white-space: nowrap;
      overflow: hidden;
      text-transform: uppercase;
    }

    .observation-value {
      overflow-wrap: break-word;
      white-space: pre-line;
    }
  }

  .flag {
    padding: 1rem;

    font-size: 1.3rem;
    font-weight: 500;
    color: $white-color;
    width: fit-content;
    margin-top: -0.5rem;
    border-radius: 0.6rem;

    &.unapproved, &.debtor {
      background-color: $red-color;
    }

    &.approved {
      background-color: $green-color;
    }

    &.error, &.unexecuted {
      background-color: $yellow-color;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2.5rem;

    button {
      margin: 0;
      width: fit-content;
    }

    .retry {
      color: $white-color;
      background-color: $primary-color;
    }

    .send-contract{
      color: $white-color;
      background-color: $primary-color;
    }

    .sent-contract{
      color: $white-color;
      background-color: $grey-color-light;
      pointer-events: none;
      border: none;
    }
  }

  @media (max-width: 450px) {
    .buttons-container {
      flex-direction: column;

      button {
        width: 100%;
        margin: 0;
      }
    }
  }

  .saas-message {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
    font-size: 1.1rem;
  }

  .loader {
    border: 8px solid transparent;
    border-radius: 50%;
    border-top: 8px solid $primary-color;
    width: 70px;
    height: 70px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-bottom: 1rem;
  }

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
</style>
