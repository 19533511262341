<template lang="pug">
.contract-section
  .inline-section
    input-field.three-columns(
      type="text"
      pattern="[A-Za-z0-9]+"
      name="contract[code]"
      :placeholder="$t('.placeholder.code')"
      :error="errors?.code?.message"
      v-model="modelValue.code"
      required
    )

    input-field.three-columns(
      type="date"
      name="contract[start_date]"
      :placeholder="$t('.placeholder.startDate')"
      :min="getCurrentDate"
      :error="errors?.startDate?.message"
      v-model="modelValue.startDate"
      required
    )

    input-field.three-columns(
      type="number"
      name="contract[months_until_deadline]"
      :placeholder="$t('.placeholder.monthsUntilDeadline')"
      :error="errors?.monthsUntilDeadline?.message"
      v-model="modelValue.monthsUntilDeadline"
      required
    )

  .inline-section
    input-field.three-columns(
      type="number"
      max="31"
      name="contract[due_day]"
      :placeholder="$t('.placeholder.dueDay')"
      :error="errors?.dueDay?.message"
      v-model="modelValue.dueDay"
      required
    )

    months-field.three-columns(
      name="contract[readjustment_month]"
      :placeholder="$t('.placeholder.readjustmentMonth')"
      :error="errors?.readjustmentMonth?.message"
      v-model="modelValue.readjustmentMonth"
      required
    )

    currency-field.three-columns(
      name="contract[rent_value]"
      :placeholder="$t('.placeholder.rentValue')"
      :error="errors?.rentValue?.message"
      @input="fetchNewFee"
      v-model="modelValue.rentValue"
      required
    )

  .inline-section
    select-field.three-columns(
      name="contract[contract_type]"
      :options="contractTypeOptions"
      :error="errors?.contractType?.message"
      v-model="modelValue.contractType"
      includeBlank
      required
    )

    select-field.three-columns(
      name="contract[readjustment_index]"
      :options="readjustmentIndexOptions"
      :error="errors?.readjustmentIndex?.message"
      v-model="modelValue.readjustmentIndex"
      includeBlank
      required
    )

    select-field.three-columns(
      name="contract[payment_type]"
      :options="paymentTypeOptions"
      :error="errors?.paymentType?.message"
      v-model="modelValue.paymentType"
      includeBlank
      required
    )

  .inline-section
    file-field.three-columns(
      type="file"
      name="contract[rental_contract]"
      accept=".doc,.docx,.pdf"
      :error="errors?.rentalContract?.message"
      v-model="modelValue.rentalContract"
      destroyable
    )

    file-field.three-columns(
      type="file"
      name="contract[admin_contract]"
      accept=".doc,.docx,.pdf"
      :error="errors?.adminContract?.message"
      v-model="modelValue.adminContract"
      destroyable
    )

    file-field.three-columns(
      type="file"
      name="contract[procuration]"
      accept=".doc,.docx,.pdf"
      :error="errors?.procuration?.message"
      v-model="modelValue.procuration"
      destroyable
    )

  .inline-section
    textarea-field.full-size(
      name="contract[observation]"
      :maxLength="1500"
      :placeholder="$t('.placeholder.observation')"
      :error="errors?.observation?.message"
      v-model="modelValue.observation"
    )
</template>

<script>
export default {
  data() {
    return {
      // i18n
      defaultI18nScope: 'realEstate.management.contract.new'
    }
  },

  props: {
    modelValue: { type: Object, required: true },
    errors: { type: Object, default: () => ({}) },
    fetchNewFee: { type: Function, default: () => {} }
  },

  computed: {
    getCurrentDate() {
      return new Date().toISOString().split('T')[0]
    },

    readjustmentIndexOptions() {
      return [
        { id: 'igpm', text: this.$t('.readjustmentIndex.igpm') },
        { id: 'inpc', text: this.$t('.readjustmentIndex.inpc') },
        { id: 'ipca', text: this.$t('.readjustmentIndex.ipca') },
        { id: 'igpdi', text: this.$t('.readjustmentIndex.igpdi') }
      ]
    },

    contractTypeOptions() {
      return [
        { id: 'commercial', text: this.$t('.contractType.commercial') },
        { id: 'non_residential', text: this.$t('.contractType.nonResidential') },
        { id: 'residential', text: this.$t('.contractType.residential') }
      ]
    },

    paymentTypeOptions() {
      const paymentType = this.$agency.get.contracts_payment_type

      const options = [
        { id: 'month_to_expire', text: this.$t('.paymentType.monthToExpire') },
        { id: 'expired_month', text: this.$t('.paymentType.expiredMonth') }
      ];

      if (paymentType === 'all_types') return options

      return options.filter(option => option.id === paymentType)
    }
  }
}
</script>

<style lang="scss" scoped>
.contract-section {
  margin-bottom: 3rem;
}
</style>
