<template lang="pug">
.application.desktop
  loading.loading

  notifications.notifications

  router-view(v-slot="{ Component }")
    transition(
      :name="transition.name",
      :mode="transition.mode",
      @before-enter="beforeEnter"
    )
      component(:is="Component")
</template>

<script>
import objects from "lib/objects"
import { useHead } from '@vueuse/head'

const DEFAULT_ROUTE_TRANSITION_NAME = "none"
const DEFAULT_ROUTE_TRANSITION_MODE = "out-in"

export default {
  setup() {
    useHead({
      meta: [
        {
          name: 'facebook-domain-verification',
          content: process.env.VUE_APP_FACEBOOK_META,
        }
      ],
      script: [
        {
          type: 'text/javascript',
          async: true,
          src: process.env.VUE_APP_RD_STATION_TAG
        }
      ]
    })
  },

  watch: {
    $route(to) {
      // definindo transição entre rotas
      this.transition = {
        name:
          objects.dig(to, "meta", "transition", "name") ||
          DEFAULT_ROUTE_TRANSITION_NAME,
        mode:
          objects.dig(to, "meta", "transition", "mode") ||
          DEFAULT_ROUTE_TRANSITION_MODE,
      }
    },
  },

  data() {
    return {
      transition: {
        name: DEFAULT_ROUTE_TRANSITION_NAME,
        mode: DEFAULT_ROUTE_TRANSITION_MODE,
      },
    }
  },

  methods: {
    // XXX Devido a transição não conseguimos usar o scrollBehavior (https://router.vuejs.org/en/advanced/scroll-behavior.html)
    // Por isso tratamos o scrollTop aqui, após o final da transição.
    beforeEnter() {
      window.scrollTo(0, 0)
    },
  },
}
</script>

<style lang="scss">
@import "src/styles/desktop";

.application.desktop {
  height: 100vh;
  width: 100%;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-index-always-visible + 2; // XXX Deve ficar acima da navbar
}

.notifications {
  position: fixed;
  top: 6px; // tamanho do loading (4px) + extra (2px)
  left: 8px;
  right: 8px;
  z-index: $z-index-always-visible + 1;
}
</style>