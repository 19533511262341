import { initializeApp } from "firebase/app"
import { getRemoteConfig, fetchAndActivate, getValue } from "firebase/remote-config"

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)

// Initialize Remote Config and get a reference to the service
const remoteConfig = process.env.NODE_ENV == 'test' ? {} : getRemoteConfig(firebaseApp)

remoteConfig.settings = {
  fetchTimeMillis: 60000,
  minimumFetchIntervalMillis: 1800000 // get updates 30 by 30 minutes
}

remoteConfig.defaultConfig = ({
  "enableCnabPage": {
    "real-estate-agency": [],
    "real-estate-admin": [],
    "real-estate-attendant": [],
    "real-estate-manager": []
  },
  "enableReceivementActions": {
    "real-estate-agency": [],
    "real-estate-admin": [],
    "real-estate-attendant": [],
    "real-estate-manager": []
  },
  "enableManualAnalysis": '[]'
})

const firebase = {
  fetchConfig() {
    return fetchAndActivate(remoteConfig)
  },

  remoteConfigValue(name) {
    return JSON.parse(getValue(remoteConfig, name)._value)
  }
}

export default firebase