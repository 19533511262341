<template lang="pug">
.info-container
  .inline-section
    .four-columns
      h3.info-title {{ $t('.name') }}
      .loading-content(v-if="loading")
      p.info-content(v-else) {{ bill.name || '-' }}

    .four-columns
      h3.info-title {{ $t('.email') }}
      .loading-content(v-if="loading")
      p.info-content(v-else) {{ bill.email || '-' }}

    .four-columns
      h3.info-title {{ $t('.value') }}
      .loading-content(v-if="loading")
      p.info-content(v-else) {{ `R$ ${formatValue(bill.value)}` }}

    .four-columns
      h3.info-title {{ $t('.status') }}
      .loading-content(v-if="loading")
      .flag(v-else, :class="bill.status ? `flag ${bill.status}` : 'info-content'")
        | {{ bill.status ? $t(`.flag.${snakeToCamel(bill.status)}`) : '-'}}

  .inline-section
    .four-columns
      h3.info-title {{ $t('.transferDueDate') }}
      .loading-content(v-if="loading")
      p.info-content(v-else) {{ formatDate(bill.transferDate) }}

    .four-columns
      h3.info-title {{ $t('.transfer') }}
      .loading-content(v-if="loading")
      p.info-content(v-else) {{ formatDate(bill.transferLiquidationDate) }}

    .four-columns
      h3.info-title {{ $t('.dueDate') }}
      .loading-content(v-if="loading")
      p.info-content(v-else) {{ formatDate(bill.dueDate) }}

    .four-columns
      h3.info-title {{ $t('.paymentDate') }}
      .loading-content(v-if="loading")
      p.info-content(v-else) {{ formatDate(bill.paymentDate) }}
</template>
<script>

export default {
  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.components.contract.bill.billDetails"
    }
  },

  props: {
    bill: { type: Object, required: true },
    loading: { type: Boolean, default: false }
  },

  methods: {
    formatDate(date) {
      return date ? this.$l('date.formats.default', date) : '-'
    },

    snakeToCamel(snake) {
      return snake.replace(/(_\w)/g, (matches) => matches[1].toUpperCase())
    },

    formatValue(value) {
      return value ? value.toString().replace('.', ',') : ''
    }
  }
}
</script>
<style scoped lang="scss">
.info-container {
  .inline-section {
    display: flex;
    word-break: break-word;

    .info-title {
      font-size: 1.8rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
      color: $second-color-light;
    }

    .loading-content {
      background: $loading-gradient;
      border-radius: 5px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
      width: 100%;
      height: 3.5rem;
      margin-bottom: 1.5rem;
    }

    .info-content {
      font-size: 1.5rem;
      font-family: Lato;
      margin-bottom: 2.5rem;
    }

    .flag {
      padding: 1rem;

      font-size: 1.3rem;
      font-weight: 500;
      color: $white-color;
      width: fit-content;
      margin-top: 0.5rem;
      border-radius: 0.6rem;

      &.waiting {
        background-color: $grey-color;
      }

      &.paid, &.manual_payment {
        background-color: $primary-color;
      }

      &.unpaid {
        background-color: $red-color;
      }

      &.agreement {
        background-color: $blue-color;
      }

      &.normal {
        background-color: $yellow-color;
      }

      &.canceled, &.chargeback {
        background-color: $second-color;
      }
    }

    .two-columns {
      width: 50%;
      padding-right: 2.5rem;
    }

    .three-columns {
      width: 33.33%;
      padding-right: 2.5rem;
    }

    .four-columns {
      width: 25%;
      padding-right: 2.5rem;
    }

    .full-size {
      width: 100%;
    }
  }
}
</style>