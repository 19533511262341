<template lang="pug">
#page.new-contract-page
  navbar(currentPage="realEstateManagementIndex")

  loading-div.form-container-desktop(v-if="scoreReport")
    .new-contract-container
      breadcrumbs(:items="breadcrumbItems")

      form(ref="form" action="/" @submit.prevent="showConfirmationModal = true")
        step-card(
          :title="$t('.form.contractInfo')"
          :expanded="isCurrent('contract')"
          :expand="() => { setStep('contract') }"
          v-bind="commonStepCardProps"
        )
          contract(
            :errors="errors"
            :fetchNewFee="fetchNewFee"
            v-model="form.contract"
          )

        step-card(
          :title="$t('.form.propertyInfo')"
          :expanded="isCurrent('property')"
          :expand="() => { setStep('property') }"
          v-bind="commonStepCardProps"
        )
          property(
            :errors="errors"
            v-model="form.property"
          )

        step-card(
          :title="$t('.form.landlordInfo')"
          :expanded="isCurrent('landlord')"
          :expand="() => { setStep('landlord') }"
          v-bind="commonStepCardProps"
        )
          landlord(
            :errors="errors"
            :landlordTransferAllowed="landlordTransferAllowed"
            v-model="form.landlord"
          )

        step-card(
          :title="$t('.form.tenantInfo')"
          :expanded="isCurrent('tenant')"
          :expand="() => { setStep('tenant') }"
          v-bind="commonStepCardProps"
        )
          tenant(
            :errors="errors"
            :scoreReport="scoreReport"
            v-model="form.tenant"
          )

        step-card(
          :title="$t('.form.billingInfo')"
          :expanded="isCurrent('billing')"
          :expand="() => { setStep('billing') }"
          v-bind="commonStepCardProps"
          sender
        )
          billing(
            :errors="errors"
            :scoreReport="scoreReport"
            :fetchNewFee="fetchNewFee"
            :newFee="newFee"
            v-model="form.billing"
          )

      billing-card(
        v-if="contractId"
        :billsPath="`${url}/${contractId}/bills`"
        :callback="() => { redirectToContractShow(contractId) }"
      )

  confirmation-modal(
    :show="showConfirmationModal"
    :title="$t('.confirmationModal.title')"
    :message="$t('.confirmationModal.message')"
    :callback="submit"
    :loading="submitting"
    @close="showConfirmationModal = false"
  )
</template>
<script>
import { useIndexedDB } from '@/composables/useIndexedDB'

import objects from "lib/objects"
import strings from "lib/strings"

import tool from "lib/vue-tool"
import vueAuthenticate from "lib/vue-authenticate"

import Navbar from "../../../components/navbar.vue"
import Breadcrumbs from "../../../../components/breadcrumbs.vue"
import ConfirmationModal from "../../../../components/confirmation-modal.vue"
import StepCard from "../../../components/contract/new/step-card.vue"
import Contract from "../../../components/contract/new/steps/contract.vue"
import Property from "../../../components/contract/new/steps/property.vue"
import Landlord from "../../../components/contract/new/steps/landlord.vue"
import Tenant from "../../../components/contract/new/steps/tenant.vue"
import Billing from "../../../components/contract/new/steps/billing.vue"
import BillingCard from "../../../components/contract/new/billing-card.vue"

export default {
  mixins: [tool, vueAuthenticate],
  components: {
    Breadcrumbs,
    Navbar,
    ConfirmationModal,
    StepCard,
    Contract,
    Property,
    Landlord,
    Tenant,
    Billing,
    BillingCard
  },

  data() {
    const { saveItem, getItem, deleteItem } = useIndexedDB(
      'forms', 'newContracts'
    )

    return {
      // i18n
      defaultI18nScope: "realEstate.management.contract.new",

      // tool
      routeName: "realEstateContractNew",
      routePath: "imobiliaria/gerenciamento/contratos",
      viewName: "new",

      submitting: false,
      showConfirmationModal: false,

      errors: {},
      scoreReport: null,
      newFee: null,

      currentStep: 'contract',
      steps: ['contract', 'property', 'landlord', 'tenant', 'billing'],
      contractId: null,

      form: {
        contract: { startDate: (new Date()).toISOString().split('T')[0] },
        property: {},
        landlord: {},
        tenant: {},
        billing: {}
      },

      saveItem,
      getItem,
      deleteItem
    }
  },

  mounted() {
    this.getItem(this.getIdParam).then(data => {
      if (data) {
        this.form = data
      }
    })
  },

  watch: {
    form: {
      handler() {
        this.form.updatedAt = new Date().toISOString()

        this.saveItem(
          { id: this.getIdParam, ...JSON.parse(JSON.stringify(this.form)) }
        )
      },
      deep: true
    }
  },

  computed: {
    getIdParam() {
      return this.$route.query.score_report_id
    },

    breadcrumbItems() {
      const managementPath = this.routePath.replace('/contratos', '')

      return [
        {
          name: this.$t('.breadcrumbs.management'),
          url: managementPath
        },
        {
          name: this.$t('.breadcrumbs.contracts'),
          url: managementPath,
          hash: 'contracts'
        },
        {
          name: this.$t('.breadcrumbs.newContract'),
          url: `${this.routePath}/criar`,
          query: { score_report_id: this.getIdParam }
        }
      ]
    },

    commonStepCardProps() {
      return {
        editable: !this.contractId,
        disableButton: this.submitting,
        errors: this.errors,
        collapse: this.collapse,
        confirm: this.confirm
      }
    },

    url() {
      return `${this.$user.prefixUrlAgency}/contracts`
    },

    landlordTransferAllowed() {
      return this.scoreReport?.realEstateAgencyLandlordTransferAllowed
    }
  },

  methods: {
    parseRoute() {},

    isCurrent(step) {
      return this.currentStep === step
    },

    setStep(step) {
      this.currentStep = step
    },

    collapse() {
      this.currentStep = null
    },

    submitValidation() {
      if (this.$refs.form.checkValidity()) {
        this.showConfirmationModal = true

        return
      }

      this.$notifications.error(this.$t(".notifications.submit.validation"))
    },

    confirm() {
      if (this.currentStep === 'billing') {
        this.collapse()
        this.submitValidation()

        return
      }

      const currentStepIndex = this.steps.findIndex(
        step => step === this.currentStep
      )

      this.currentStep = this.steps[currentStepIndex + 1]
    },

    redirectToContractShow(contractId) {
      if (!contractId) return

      this.$router.push({
        name: 'realEstateContractShow',
        params: { id: contractId }
      })
    },

    fetch() {
      return this.$http
        .get(`${this.$user.prefixUrlAgency}/score-reports/${this.getIdParam}`)
        .then((response) => {
          const scoreReport = response.data

          if (scoreReport.contract) {
            this.redirectToContractShow(scoreReport.contract.id)
          }

          this.scoreReport = objects.camelize(scoreReport)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
    },

    currencyToFloat(currency) {
      return parseFloat(currency.replace(/[R$]+/g,"")) || 0
    },

    fetchNewFee() {
      if (!this.scoreReport || this.scoreReport.hidedServices.length == 0) return

      const formData = new FormData(this.$refs.form)
      const rentValue = this.currencyToFloat(formData.get('contract[rent_value]'))
      const fireInsurance_amount = this.currencyToFloat(formData.get('contract[fire_insurance_amount]'))
      const condominiumAmount = this.currencyToFloat(formData.get('contract[condominium_amount]'))
      const iptuAmount = this.currencyToFloat(formData.get('contract[iptu_amount]'))
      const totalValue = rentValue + fireInsurance_amount + condominiumAmount + iptuAmount

      let partialValue = 0

      this.scoreReport.hidedServices.forEach(service => {
        partialValue += eval(strings.camelize(service))
      })

      if (partialValue == totalValue) return

      const params = {
        fee: this.scoreReport.fee,
        total_value: totalValue,
        partial_value: partialValue
      }

      this.submitting = true

      this.$http
        .post(`${this.$user.prefixUrlAgency}/hided-service-fees`, params)
        .then((response) => {
          this.newFee = response.data.fee

          this.submitting = false
        })
        .catch(() => {
          this.fetchNewFee()
        })
    },

    formatCurrencyValue(value) {
      return value && value.replace('R$', '').trim()
    },

    isEnabledToEditFirstBill() {
      const config = this.$firebase.remoteConfigValue('enableFirstBillEdition')
      const allowedAgenciesIds = config['real-estate-agency'] || []

      return allowedAgenciesIds.includes(this.scoreReport.realEstateAgencyId)
    },

    submit() {
      const formData = new FormData(this.$refs.form)

      formData.delete('contract[tenant]')

      formData.set('contract[rent_value]', this.formatCurrencyValue(formData.get('contract[rent_value]')))
      formData.set('contract[fire_insurance_amount]', this.formatCurrencyValue(formData.get('contract[fire_insurance_amount]')))
      formData.set('contract[condominium_amount]', this.formatCurrencyValue(formData.get('contract[condominium_amount]')))
      formData.set('contract[iptu_amount]', this.formatCurrencyValue(formData.get('contract[iptu_amount]')))
      formData.set('contract[rent_discount]', this.formatCurrencyValue(formData.get('contract[rent_discount]')))

      formData.append('contract[score_report_id]', this.getIdParam)

      this.submitting = true

      this.$http
        .post(this.url, formData)
        .then(response => {
          this.errors = {}
          this.$notifications.clear()
          this.$notifications.info(this.$t(".notifications.submit.success"))

          this.deleteItem(this.getIdParam)

          const contract = response.data
          const isSuperlogica = contract.issuer == 'superlogica'

          if (isSuperlogica || !this.isEnabledToEditFirstBill()) {
            this.redirectToContractShow(contract.id)

            return
          }

          this.contractId = contract.id
        })
        .catch((err) => {
          this.errors = objects.camelize(
            objects.dig(err, "response", "data", "errors") || {}
          )

          if (this.errors?.scoreReport?.type == 'expired_date') {
            this.$notifications.error(this.$t(".notifications.submit.expired_date"))
          } else {
            this.$notifications.error(this.$t(".notifications.submit.failure"))
          }
        })
        .finally(() => {
          this.showConfirmationModal = false
          this.submitting = false
        })
    }
  }
}
</script>
<style scoped lang="scss">
.new-contract-page {
  overflow: hidden;

  .new-contract-container {
    padding: 2.5rem 7rem 5rem 7rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    form {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }
}
</style>
