<template lang="pug">
.bill-section
  .inline-section
    currency-field.three-columns(
      name="contract[fire_insurance_amount]"
      :placeholder="$t('.placeholder.fireInsuranceAmount')"
      :error="errors?.fireInsuranceAmount?.message"
      @input="fetchNewFee"
      v-model="modelValue.fireInsuranceAmount"
      required
    )

    input-field.three-columns(
      type="number"
      name="contract[fire_insurance_installments]"
      :placeholder="$t('.placeholder.fireInsuranceInstallments')"
      :error="errors?.fireInsuranceInstallments?.message"
      v-model="modelValue.fireInsuranceInstallments"
      required
    )

    currency-field.three-columns(
      name="contract[condominium_amount]"
      :placeholder="$t('.placeholder.condominiumAmount')"
      :error="errors?.condominiumAmount?.message"
      @input="fetchNewFee"
      v-model="modelValue.condominiumAmount"
      required
    )

  .inline-section
    currency-field.three-columns(
      name="contract[iptu_amount]"
      :placeholder="$t('.placeholder.iptuAmount')"
      :error="errors?.iptuAmount?.message"
      @input="fetchNewFee"
      v-model="modelValue.iptuAmount"
      required
    )

    input-field.three-columns(
      type="number"
      name="contract[iptu_installments]"
      :placeholder="$t('.placeholder.iptuInstallments')"
      :error="errors?.iptuInstallments?.message"
      v-model="modelValue.iptuInstallments"
      required
    )

    input-field.three-columns(
      type="number"
      name="contract[iptu_exemption_months]"
      :placeholder="$t('.placeholder.iptuExemptionMonths')"
      :error="errors?.iptuExemptionMonths?.message"
      v-model="modelValue.iptuExemptionMonths"
      required
    )

  .inline-section
    currency-field.three-columns(
      name="contract[rent_discount]"
      :placeholder="$t('.placeholder.rentDiscount')"
      :error="errors?.rentDiscount?.message"
      v-model="modelValue.rentDiscount"
    )

    input-field.three-columns(
      type="number"
      name="contract[rent_discount_months]"
      :placeholder="$t('.placeholder.rentDiscountMonths')"
      :error="errors?.rentDiscountMonths?.message"
      v-model="modelValue.rentDiscountMonths"
    )

    select-field.three-columns(
      name="contract[closed_month]"
      :options="closedMonthOptions"
      :error="errors?.closedMonth?.message"
      v-model="modelValue.closedMonth"
      includeBlank
      required
    )

  .hided-fee-container(v-if="hasHidedServices")
    h3 {{ $t('.form.alpopFee') }}

    p {{ $t('.form.fields.alpopNewFee') }} <b>{{ updatedFee }}%</b>
</template>

<script>
export default {
  data() {
    return {
      // i18n
      defaultI18nScope: 'realEstate.management.contract.new'
    }
  },

  props: {
    modelValue: { type: Object, required: true },
    errors: { type: Object, default: () => ({}) },
    scoreReport: { type: Object, default: null },
    fetchNewFee: { type: Function, default: () => {} },
    newFee: { type: Number, default: null }
  },

  computed: {
    updatedFee() {
      if (!this.scoreReport) return

      return this.newFee || this.scoreReport.fee
    },

    hasHidedServices() {
      if (!this.scoreReport) return

      return this.scoreReport.hidedServices.length > 0
    },

    closedMonthOptions() {
      return [
        {
          id: 'opened',
          text: this.$t('.closedMonthOptions.opened')
        },
        {
          id: 'closed',
          text: this.$t('.closedMonthOptions.closed')
        }
      ]
    }
  }
}
</script>
