<template lang="pug">
Modal(:show="show" :onClose="close")
  .partners-container
    .header-container
      img(v-if="noData" src="@/assets/images/no-data.svg" alt="No data")

      img(v-else src="@/assets/images/location.svg" alt="Location")

      h1 {{ $t('.title') }}

    .filter-container
      select-field(
        name="state"
        v-model="state"
        :options="stateOptions"
        :label="$t('.label.state')"
        :hideSearch="false"
        includeBlank
        allowClear
        required
      )

      select-field(
        name="city"
        v-model="city"
        :options="cityOptions"
        :label="$t('.label.city')"
        :hideSearch="false"
        :disabled="!state || !cities.length"
        :key="state"
        includeBlank
        allowClear
        required
      )

    .content-container(@scroll="autoLoad")
      .list-section(v-if="agencies.length")
        a.item(
          v-for="(agency, index) in agencies"
          :key="index"
          :href="getAbsoluteUrl(agency.url)"
          target="_blank"
        )
          img.icon-second(src="@/assets/images/pin-second.svg" alt="Pin icon")
          img.icon-primary(src="@/assets/images/pin-primary.svg" alt="Pin icon")

          .infos
            p.name {{ agency.name }}

            p.address {{ fullAddress(agency) }}

      .message-container(v-else)
        template(v-if="noData")
          h3 {{ $t('.noData') }}

          router-link(:to="{ name: 'contact' }") {{ $t('.contactUs') }}

        h3(v-else) {{ $t('.defaultMessage') }}
</template>
<script>
import objects from 'lib/objects'

import Modal from '../../../components/modal.vue'

export default {
  components: { Modal },

  props: {
    show: { type: Boolean, default: false }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "staticPages.components.partnersModal",

      // filter
      apiParams: { orderBy: 'nome' },
      apiUrl: process.env.VUE_APP_IBGE_API_URL,

      state: null,
      states: [],
      city: null,
      cities: [],

      // table
      requested: false,
      loading: false,
      agencies: [],
      pagination: { total: 0 }
    }
  },

  watch: {
    state(newState) {
      this.resetCityAndAgencies()
      this.fetchCitiesByUf(newState)
    },

    city(newCity) {
      if (newCity === null) {
        this.resetAgencies()

        return
      }

      this.fetchAgencies()
    }
  },

  mounted() {
    this.fetchStates()
  },

  computed: {
    stateOptions() {
      return this.states.map(({ id, sigla, nome }) => (
        { id, text: `${nome} (${sigla})` }
      ))
    },

    cityOptions() {
      return this.cities.map(({ id, nome }) => ({ id, text: nome }))
    },

    noData() {
      return !this.loading && this.requested && !this.agencies?.length
    }
  },

  methods: {
    fullAddress({ street, number, neighborhood, city, state }) {
      return `${street}, nº ${number} - ${neighborhood}, ${city} - ${state}`
    },

    getAbsoluteUrl(url) {
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return `http://${url}`
      }

      return url
    },

    resetCityAndAgencies() {
      this.city = null
      this.cities = []

      this.resetAgencies()
    },

    resetAgencies() {
      this.requested = false
      this.agencies = []
      this.pagination = { total: 0 }
    },

    close() {
      this.state = null
      this.resetCityAndAgencies()

      this.$emit('close')
    },

    fetchStates() {
      this.$http
        .get(`${this.apiUrl}/estados`, { params: this.apiParams })
        .then(({ data }) => { this.states = data })
    },

    fetchCitiesByUf(ufId) {
      if (!ufId) return

      this.$http
        .get(
          `${this.apiUrl}/estados/${ufId}/municipios`,
          { params: this.apiParams }
        )
        .then(({ data }) => { this.cities = data })
    },

    init(agencies, pagination) {
      this.agencies.push(...objects.camelize(agencies))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total")
      }
    },

    getOption(id, options) {
      return options.find(option => option.id == id)
    },

    httpParams(page = null) {
      return {
        page,
        state: this.getOption(this.state, this.states)?.sigla,
        city: this.getOption(this.city, this.cities)?.nome
      }
    },

    fetchAgencies(page = null) {
      this.loading = true

      if (page === null) this.agencies = []

      this.$http
        .get('/partners/agencies', { params: this.httpParams(page) })
        .then(({ data }) => {
          const agencies = data.data
          const pagination = data.pagination || {}

          this.init(agencies, pagination)
        })
        .finally(() => {
          this.loading = false
          this.requested = true
        })
    },

    autoLoad(event) {
      if (this.loading || !this.agencies?.length) return

      const { scrollTop, scrollHeight, clientHeight } = event.target
      const loadingHeight = scrollHeight - 5

      if ((scrollTop + clientHeight < loadingHeight) || !this.pagination.next) {
        return
      }

      this.fetchAgencies(this.pagination.next)
    }
  }
}
</script>
<style lang="scss" scoped>
.partners-container {
  width: 70vw;
  height: 75vh;
  font-family: 'Open Sans';
  display: grid;
  grid-template-rows: auto auto 1fr;

  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 30%;
      max-height: 20vh;
      margin-bottom: 2.2rem;
    }

    h1 {
      font-size: 2.5rem;
      font-weight: 550;
      letter-spacing: 0.001rem;
      color: $second-color-dark;
    }
  }

  .filter-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;

    .select-field {
      margin: 0;
    }
  }

  .content-container {
    overflow: auto;
    margin-top: 2rem;

    .list-section {
      display: flex;
      flex-direction: column;

      a.item {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        padding-block: 1rem;
        border-radius: 0.5rem;
        color: $second-color-light;

        img {
          height: 3rem;

          &.icon-primary {
            display: none;
          }
        }

        .infos {
          p {
            margin: 0;

            &.name {
              color: $second-color;
              font-size: 1.5rem;
              font-weight: 550;
            }

            &.address {
              font-size: 1.3rem;
              font-weight: 550;
            }
          }
        }

        &:hover {
          img {
            &.icon-second {
              display: none;
            }

            &.icon-primary {
              display: block;
            }
          }

          .infos {
            p {
              &.name {
                color: $primary-color;
              }

              &.address {
                color: $second-color;
              }
            }
          }
        }
      }
    }
  }


  .message-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      font-size: 1.8rem;
      font-weight: 550;
      font-style: italic;
      color: $grey-color-dark;
      text-align: center;
      margin-bottom: 1rem;
    }

    a {
      font-size: 2.2rem;
      font-weight: 700;
    }
  }
}

@media only screen and (min-width: 930px) {
  .partners-container {
    .filter-container {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media only screen and (max-width: 500px) {
  .partners-container {
    width: 100%;

    .no-data-container {
      img {
        width: 50%;
        margin-block: 4rem;
      }
    }
  }
}
</style>