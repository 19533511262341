import axios from 'axios'
import qs from 'qs'
import authToken from './auth_token'
import loading from './loading'

// dados privados
const data = {
  count: 0
}

const router = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json, text/plain, */*',
    'X-Requested-With': 'XMLHttpRequest',
    'X-Content-Type-Options': 'nosniff',
    'Referrer-Policy': 'strict-origin-when-cross-origin'
  },
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

// Interceptors for request and response handling
router.interceptors.request.use(
  (config) => {
    // Adding authentication tokens to the request headers
    config.headers[authToken.accessTokenHEADER] = authToken.accessToken
    config.headers[authToken.clientHEADER] = authToken.client
    config.headers[authToken.uidHEADER] = authToken.uid

    // Increment the loading count and start loading state
    loading.start()
    data.count++

    return config
  },

  // Quando houver erro no envio de requisição, queremos:
  //   - desativar estado de "carregamento" da app
  (error) => {
    loading.stop()
    data.count--

    if (error.response.status == 401) {
      // app.router.replace({ name: 'unauthorized' })
    }

    // TODO integrar com módulo de notificações e/ou router!
    // Use `error.response.status` para verificar:
    // - 400 - bad_request - falha em preenchimento de dados
    // - 403 - forbidden (não tem permissão)
    // - 404 - not found
    // - 422 - falha de verificação CSRF e CORS
    // - 500 - internal server error

    return Promise.reject(error)
  }
)

// Intercepting responses to manage loading state and update authentication tokens
router.interceptors.response.use(
  (response) => {
    // Decrement the loading count and stop loading state
    loading.stop()
    data.count--

    // Update authentication tokens from response headers if they've changed
    // key is the response header name, value is the token property name
    // Using computed property names to dynamically set the property name
    const tokenMapping = {
      [authToken.accessTokenHEADER]: 'accessToken',
      [authToken.clientHEADER]: 'client',
      [authToken.uidHEADER]: 'uid'
    }

    // Process all tokens in a single loop
    Object.entries(tokenMapping).forEach(([headerKey, tokenProp]) => {
      const newValue = response.headers[headerKey];

      if (newValue && newValue !== authToken[tokenProp]) {
        authToken[tokenProp] = newValue;
      }
    })

    return response
  },

  // Quando houver erro no processamento de uma resposta, queremos:
  //   - desativar estado de "carregamento" da app
  (error) => {
    loading.stop()
    data.count--

    const response = error.response
    const config = response?.config
    const req_method = config?.method
    const current_role = app.router.currentRoute.value.meta.role

    if (response?.status !== 401) return Promise.reject(error)

    if (
      current_role != null && (
        current_role == app.store.get('role') || [
          'real-estate-admin', 'real-estate-manager', 'real-estate-attendant'
        ].includes(current_role)
      )
    ) {
      const prefix = app.auth.camelizedRole === 'admin' ? 'admin' : 'realEstate'

      app.router.replace({ name: `${prefix}Login` })
      app.auth.clear()

      return
    }

    if (req_method == 'get') {
      app.router.replace({ name: 'unauthorized' })
    }

    return Promise.reject(error)
  }
)

/**
 * HTTP client utility for making API requests with automatic token management.
  * It uses Axios for making requests and manages loading state and authentication tokens.
  *
  *
 * @namespace http - HTTP client utility
 * @property {boolean} active - Indicates if there are ongoing requests
 * @property {Object} router - The router object that can be used to add interceptors
 * @method request - Makes a generic HTTP request
 * @method get - Makes a GET HTTP request
 * @method post - Makes a POST HTTP request
 * @method put - Makes a PUT HTTP request
 * @method delete - Makes a DELETE HTTP request
 */
const http = {
  // Indicates if there are ongoing requests
  get active() {
    return data.count > 0
  },

  // Expose router for interceptors/configurations
  router,

  // These methods can be used to make requests with the same parameters as Axios
  // and they will automatically handle loading state and authentication tokens
  // The methods are implemeted with rest parameters to accept any number of arguments
  // and they will be passed to the corresponding Axios method by method fowarding pattern
  request: (...args) => router.request(...args),
  get: (...args) => router.get(...args),
  post: (...args) => router.post(...args),
  put: (...args) => router.put(...args),
  delete: (...args) => router.delete(...args)
}

export default http
