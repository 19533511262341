import { ref } from 'vue'

/**
 * Opens the IndexedDB database and creates the object store if needed.
 * @param {string} dbName - The name of the database.
 * @param {string} storeName - The name of the object store.
 * @returns {Promise<IDBDatabase>} The opened database instance.
 */
const openDatabase = (dbName, storeName) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1)

    request.onupgradeneeded = (event) => {
      const database = event.target.result

      if (!database.objectStoreNames.contains(storeName)) {
        database.createObjectStore(storeName, { keyPath: 'id' })
      }
    }

    request.onsuccess = (event) => resolve(event.target.result)
    request.onerror = (event) => reject(event.target.error)
  })
}

/**
 * Executes a transaction on the object store.
 * @param {IDBDatabase} database - The database instance.
 * @param {string} storeName - The name of the object store.
 * @param {string} mode - The transaction mode ('readonly' or 'readwrite').
 * @param {Function} callback - The function to execute within the transaction.
 * @returns {Promise<any>} The result of the transaction.
 */
const executeTransaction = (database, storeName, mode, callback) => {
  return new Promise((resolve, reject) => {
    const transaction = database.transaction(storeName, mode)
    const store = transaction.objectStore(storeName)
    const request = callback(store)

    request.onsuccess = (event) => resolve(event.target.result)
    request.onerror = (event) => reject(event.target.error)
    transaction.onerror = (event) => reject(event.target.error)
  })
}

/**
 * A composable for interacting with IndexedDB in a generic way.
 * @param {string} dbName - The name of the database.
 * @param {string} storeName - The name of the object store.
 * @returns {Object} Methods for interacting with IndexedDB.
 */
export function useIndexedDB(dbName, storeName) {
  const db = ref(null)

  const getDatabase = async () => {
    if (!db.value) { db.value = await openDatabase(dbName, storeName) }

    return db.value
  }

  const saveItem = async (item) => {
    const database = await getDatabase()

    return executeTransaction(
      database, storeName, 'readwrite', (store) => store.put(item)
    )
  }

  const getItem = async (id) => {
    const database = await getDatabase()

    return executeTransaction(
      database, storeName, 'readonly', (store) => store.get(id)
    )
  }

  const deleteItem = async (id) => {
    const database = await getDatabase()

    return executeTransaction(
      database, storeName, 'readwrite', (store) => store.delete(id)
    )
  }

  const getAllItems = async () => {
    const database = await getDatabase()

    return executeTransaction(
      database, storeName, 'readonly', (store) => store.getAll()
    )
  }

  const deleteOldItems = async () => {
    const allItems = await getAllItems()
    const sevenDaysAgo = Date.now() - (7 * 24 * 60 * 60 * 1000)

    allItems
      .filter(item => new Date(item.updatedAt).getTime() < sevenDaysAgo)
      .forEach(async item => await deleteItem(item.id))
  }

  return { saveItem, getItem, deleteItem, getAllItems, deleteOldItems }
}