import { createRouter, createWebHistory } from 'vue-router'
import { useIndexedDB } from 'src/composables/useIndexedDB'
import cryptography from 'src/modules/cryptography'

import NotFound from '../views/static_pages/not-found.vue'
import Unauthorized from '../views/static_pages/unauthorized.vue'
import AdminUrlChanged from '../views/static_pages/admin-url-changed.vue'

// import RealEstateAgencyCandidates from '../views/desktop/real_estate_agency_candidates/RealEstateAgencyCandidates.vue'
// import About from '../views/desktop/static_pages/about.vue'
// import FAQ from '../views/desktop/static_pages/faq.vue'
// import Acknowledgment from '../views/desktop/static_pages/acknowledgment.vue'

// RealEstate
import RealEstateLogin from 'src/views/desktop/real_estate/tools/login.vue'
import RealEstateConfirmation from 'src/views/desktop/real_estate/tools/account-confirmation.vue'
import RealEstateResendConfirmation from 'src/views/desktop/real_estate/tools/resend-confirmation.vue'
import RealEstateNewPassword from 'src/views/desktop/real_estate/tools/password/new.vue'
import RealEstateEditPassword from 'src/views/desktop/real_estate/tools/password/edit.vue'
import RealEstateManagementIndex from 'src/views/desktop/real_estate/tools/management/index.vue'
import RealEstateScoreReportShow from 'src/views/desktop/real_estate/tools/management/score_report/show.vue'
import RealEstateContractNew from 'src/views/desktop/real_estate/tools/management/contract/new.vue'
import RealEstateContractShow from 'src/views/desktop/real_estate/tools/management/contract/show.vue'
import RealEstateContractBillShow from 'src/views/desktop/real_estate/tools/management/contract/bill/show.vue'
import RealEstateCnabIndex from 'src/views/desktop/real_estate/tools/cnab/index.vue'
import RealEstateTeamIndex from 'src/views/desktop/real_estate/tools/team/index.vue'

// marketing
import marketingRoutes from './marketing'
import staticPagesRoutes from './static_pages'

const routes = [
  // Public routes
  // {
  //   path: '/',
  //   name: 'realEstateAgencyCandidates',
  //   component: RealEstateAgencyCandidates
  // },
  // {
  //   name:      'about',
  //   path:      '/about',
  //   component: About,
  // },
  // {
  //   name:      'howItWorks',
  //   path:      '/como-funciona',
  //   component: About,
  // },
  // {
  //   name:      'faq',
  //   path:      '/faq',
  //   component: FAQ,
  // },
  // {
  //   name:      'acknowledgment',
  //   path:      '/obrigado',
  //   component: Acknowledgment
  // },

  // Real Estate Routes

  {
    name:      'realEstateLogin',
    path:      '/imobiliaria/login',
    component: RealEstateLogin
  },
  {
    name:      'realEstateConfirmation',
    path:      '/imobiliaria/confirmar-conta',
    component: RealEstateConfirmation
  },
  {
    name:      'realEstateResendConfirmation',
    path:      '/imobiliaria/reenviar-confirmacao',
    component: RealEstateResendConfirmation
  },
  {
    name:      'realEstateNewPassword',
    path:      '/imobiliaria/recuperar-senha',
    component: RealEstateNewPassword
  },
  {
    name:      'realEstateEditPassword',
    path:      '/imobiliaria/nova-senha',
    component: RealEstateEditPassword
  },
  {
    name:      'realEstateManagementIndex',
    path:      '/imobiliaria/gerenciamento',
    component: RealEstateManagementIndex,
    meta: {
      auth: true,
      role: 'real-estate'
    }
  },
  {
    name:      'realEstateScoreReportShow',
    path:      '/imobiliaria/gerenciamento/consultas/:id',
    component: RealEstateScoreReportShow,
    meta: {
      auth: true,
      role: 'real-estate'
    }
  },
  {
    name:      'realEstateContractNew',
    path:      '/imobiliaria/gerenciamento/contratos/criar',
    component: RealEstateContractNew,
    meta: {
      auth: true,
      role: 'real-estate'
    }
  },
  {
    name:      'realEstateContractShow',
    path:      '/imobiliaria/gerenciamento/contratos/:id',
    component: RealEstateContractShow,
    meta: {
      auth: true,
      role: 'real-estate'
    }
  },
  {
    name:      'realEstateContractBillShow',
    path:      '/imobiliaria/gerenciamento/contratos/:contractId/cobrancas/:billId',
    component: RealEstateContractBillShow,
    meta: {
      auth: true,
      role: 'real-estate'
    }
  },
  {
    name:      'realEstateCnabIndex',
    path:      '/imobiliaria/cnab',
    component: RealEstateCnabIndex,
    meta: {
      auth: true,
      role: 'real-estate'
    }
  },
  {
    name:      'realEstateTeamIndex',
    path:      '/imobiliaria/equipe',
    component: RealEstateTeamIndex,
    meta: {
      auth: true,
      role: 'real-estate'
    }
  },

  // alias for backend autentication routers
  {
    path: '/real_estate/user/confirmation',
    redirect: { name: 'realEstateConfirmation' }
  },
  {
    path: '/real_estate/user/resend-confirmation',
    redirect: { name: 'realEstateResendConfirmation' }
  },
  {
    path: '/real_estate/user/password/edit',
    redirect: { name: 'realEstateEditPassword' }
  },

  {
    path: '/admin/confirmation',
    beforeEnter: to => {
      window.location.href = `${process.env.VUE_APP_ADMIN_URL}${to.fullPath}`
    }
  },
  {
    path: '/admin/resend-confirmation',
    beforeEnter: to => {
      window.location.href = `${process.env.VUE_APP_ADMIN_URL}${to.fullPath}`
    }
  },
  {
    path: '/admin/password/edit',
    beforeEnter: to => {
      window.location.href = `${process.env.VUE_APP_ADMIN_URL}${to.fullPath}`
    }
  },

  {
    path: '/real_estate/admin/confirmation',
    redirect: { name: 'realEstateAdminConfirmation' }
  },
  {
    path: '/real_estate/admin/password/edit',
    redirect: { name: 'realEstateAdminEditPassword' }
  },

  {
    path: '/real_estate/attendant/confirmation',
    redirect: { name: 'realEstateAttendantConfirmation' }
  },
  {
    path: '/real_estate/attendant/resend-confirmation',
    redirect: { name: 'realEstateAttendantResendConfirmation' }
  },
  {
    path: '/real_estate/attendant/password/edit',
    redirect: { name: 'realEstateAttendantEditPassword' }
  },

  {
    path: '/real_estate/manager/confirmation',
    redirect: { name: 'realEstateManagerConfirmation' }
  },
  {
    path: '/real_estate/manager/resend-confirmation',
    redirect: { name: 'realEstateManagerResendConfirmation' }
  },
  {
    path: '/real_estate/manager/password/edit',
    redirect: { name: 'realEstateManagerEditPassword' }
  },

  { path: '/acesso-nao-autorizado', name: 'unauthorized', component: Unauthorized },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound },

  // Admin URL changed
  {
    path: '/administracao/:pathMatch(.*)*',
    name: 'adminUrlChanged',
    component: AdminUrlChanged
  },
  {
    path: '/administracao',
    name: 'adminNewUrl',
    beforeEnter: (to, from) => {
      const encrypt = object => (
        encodeURIComponent(cryptography.encrypt(JSON.stringify(object)))
      )
      const { accessToken, client, uid } = app.authToken
      const user = app.auth.user || null
      const queryString = []

      if (accessToken && client && uid) {
        queryString.push(`authToken=${encrypt({ accessToken, client, uid })}`)
      }

      if (user) {
        queryString.push(`user=${encrypt(user)}`)
      }

      const path = from.fullPath.replace('/administracao', '')
      const query = queryString.length ? `?${queryString.join('&')}` : ''
      const url = process.env.VUE_APP_ADMIN_URL

      window.location.href = `${url}${path}${query}`
    }
  },

  // redirect route
  {
    path: '/chamados',
    name: 'requests',
    beforeEnter: () => {
      window.location.href = process.env.VUE_APP_REQUESTS_URL
    }
  }
]

routes.push(...marketingRoutes)
routes.push(...staticPagesRoutes)

const router = createRouter({
  history: createWebHistory(),
  routes
})

const newContractsForms = {
  deleteOldItems: useIndexedDB('forms', 'newContracts').deleteOldItems
}

router.beforeEach((to, from, next) => {
  newContractsForms.deleteOldItems().finally(() => {
    app.permissions.validateOrFetch().finally(() => {
      if (app.auth.isRealEstateUserAuthenticated()) {
        app.agency.initialize().finally(() => {
          if (to.name === 'realEstateLogin') {
            next({ name: 'realEstateManagementIndex' })

            return
          }

          next()

          return
        })
      } else {
        if (to.meta?.role === 'real-estate') {
          next({ name: 'realEstateLogin' })

          return
        }

        if (to.matched.some(record => record.meta.auth) && !app.auth.isAuthenticated()) {
          let redirectTo = `${_.camelCase(to.meta.role)}Login`

          next({ name: redirectTo })

          return
        }

        next()
      }
    })
  })
})

// Guardando a rota acessada de maneira navegacional, permitindo que dados
// sejam carregados de maneira síncrona
router.afterEach((to) => {
  if (!router.navigationalRoute) {
    router.navigationalRoute = to
  }
})

export default router
